<template>
  <div>
    <div v-if="!bottomProduct" class="default-content">
      <p style="font-size: xx-large;">暂无数据</p>
      <p style="font-size: large;">请点击左侧生成订单数据</p>
    </div>

    <div v-else>
      <Row class="p-b-5">
          <i-col span="24" class="text-14">
              <span>采购媒体： {{bottomProduct.categoryName}}-{{bottomProduct.productName}} {{formatSchedule(bottomProduct.startDate,bottomProduct.endDate)}}</span>
          </i-col>
      </Row>
      <hr class="workplatform-hr"/>
      <component ref="editComponent" :is="editComponent" :params="bottomProduct"
        @on-update-order="updatedPurchaseItems"
        @on-update-map="updateMapStations"
        @on-update-city="updateMapCity"></component>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

export default {
  mixins: [sysMixins],
  components: {
    eMetroComponent: () => import('../editComponents/Metro')
  },
  data () {
    return {
      editComponent: ''
    }
  },
  mounted () {
    this.handleProductChosen()
  },
  methods: {
    formatSchedule (start, end) {
      return GetCurrentSchedule(start, end)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleProductChosen () { // 显示编辑界面
      if (!this.bottomProduct) {
        this.setBottomHeight('5px')
        return
      } else {
        this.setBottomHeight('300px')
      }

      const componentMap = [
        { productType: 1, name: 'eMetro' }, // 暂时兼容有BRT
        { productType: 2, name: 'eTheme' },
        { productType: 3, name: 'eSubway' },
        { productType: 4, name: 'eStairSign' }, // 梯牌
        { productType: 6, name: 'eGroup' }, // 套装
        { productType: 7, name: 'ePIS' }, // 地铁PIS屏
        { productType: 9, name: 'eMetroVoice' }, // 地铁语音
        { productType: 14, name: 'eElecChoose' }, // 点播屏
        { productType: 16, name: 'eMetroSeries' }, // 连封灯箱套装
        { productType: 18, name: 'eMetro' } // 北京电子屏
      ]

      const component = componentMap.find(x => x.productType === this.bottomProduct.productType) || { name: '' }
      this.editComponent = component.name + 'Component'
    },
    updatedPurchaseItems (categoryId) {
      // 如果左侧等于底部的类型，那么刷新左侧
      if (this.categoryId === categoryId) {
        this.$store.commit('set_adx_update_left', new Date())
      }

      this.$store.commit('set_adx_update_purchase', new Date())
    },
    updateMapStations (stations, cityCount) {
      this.$store.commit('set_adx_currentCityCode', null)
      this.$store.commit('set_adx_check_schedule', [{
        startDate: this.bottomProduct.startDate,
        endDate: this.bottomProduct.endDate,
        label: GetCurrentSchedule(this.bottomProduct.startDate, this.bottomProduct.endDate)
      }])

      // 开始更新全国地图
      const updateChinaMapData = {
        purchaseCityData: [], // 已采购的城市
        allowCityData: []// 允许采购的城市
      }

      cityCount.forEach(element => {
        updateChinaMapData.purchaseCityData.push({
          name: element.cityName.replace('市', ''),
          cityCode: element.cityCode,
          value: 100,
          surplus: element.quantity
        })
      })
      this.$store.commit('set_adx_chinaMap_data', updateChinaMapData)
      this.$refs.editComponent.handleChangeCityCode('')

      // 更新选中编辑媒体的站点线路信息
      this.$store.commit('set_adx_stations', stations)
      const assetIds = []
      stations.forEach(element => {
        if (!assetIds.includes(element.assetId)) {
          assetIds.push(element.assetId)
        }
      })
      this.$store.commit('set_adx_show_assets', assetIds)
    },
    updateMapCity (cityCode, allowAssetIds) {
      this.$store.commit('set_adx_currentCityCode', cityCode)

      if (cityCode !== null) {
        setTimeout(() => {
          this.$store.commit('set_adx_show_assets', allowAssetIds)
          this.$store.commit('set_adx_update_map', new Date())
        }, 1000)
      } else {
        setTimeout(() => {
          this.$store.commit('set_adx_update_China_map', new Date())
        }, 500)
      }
    }
  },
  computed: {
    bottomProduct () {
      return this.$store.state.adx.bottomProduct
    },
    mapType () {
      return this.$store.state.adx.mapType
    },
    categoryId () {
      return this.$store.state.adx.categoryId
    }
  },
  watch: {
    bottomProduct (val) {
      this.handleProductChosen()
    }
  }
}
</script>
